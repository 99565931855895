/* Slideshow.css */
.slideshow-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slideshow-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.164); /* Black background with transparency */
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  text-align: center;
  color: #F8B500;
  padding: 20px;
}

.heading {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 14px black;
}

.btn1zz {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 0;
  margin-right: 20px;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  background-color: #393E46;
  border: .5px #F8B500 solid;
  border-radius: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn1zz:hover {
  background-color: #F8B500;
  color: #393E46;
  border: #393E46 .5px solid;
}

.btn-whitez {
  background-color: #393E46;
  color: #F8B500;
}

.btn-white:hover {
  background-color: lightgray;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.col-md-10 {
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
}
