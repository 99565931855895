@media (max-width: 1199.98px) {
    main[role="main"] {
        /* padding-top: 98px; */
        padding: 10px;
        overflow: hidden;
        margin-right: 20px;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (max-width:1024px) {
    .color-orange {
        color: #F8B500;
        padding-top: 50px;
        font-size: 35px;
        margin-top: 50px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 1060px;
    }
}

.page-top-virtualphone {
    padding-bottom: 38px;
   
}
@media (min-width: 992px) {
.row34 {
    display: flex;
    flex-wrap: inherit;
    margin-right: -15px;
    margin-left: -15px;
}
}
.row3 {
    /* margin-right: -15px; */
    margin-left: -15px;
    margin-bottom: 50px;
}

.mt-5, .my-5 {
    margin-top: 3rem !important;
}

.mb-5, .my-5 {
    margin-bottom: 3rem !important;
}

.color-orange {
    color: #F8B500;
    padding-top: 50px;
    font-size: 35px;
    margin-top: 50px;
    width: 100%;
}
.color-orange2 {
    color: #F8B500;
}
/* .colsm-12{
    font-size: 20px;
    line-height: 35px;
} */

.page-top-virtualphone h2 {
    margin-bottom: 18px;
}
.col23{
    margin-bottom: 18px;
    color: #F8B500;
}
h2 {
    /* font-size: 2.4375rem; */
    line-height: 50px; 
    font-weight: 500;
    color: #393E46;
}
.font-weight-medium{
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: large;
    font-weight: 500;
    color:#F8B500
}


p {
    margin-top: 0;
    margin-bottom: 1rem;
    /* font-size: 20px; */
    line-height: 30px;
}

.card-header {
    cursor: pointer;
}

.btn-start-trial, .btn2, .btn-white {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px 0;
    font-size: 1rem;
    color: white;
    text-decoration: none;
    background-color: #393E46;
    border: .5px #F8B500 solid;
    margin-top: 50px;
    margin-bottom: 70px;
    border-radius: 20px;
    /* transition: background-color 0.3s ease, color 0.3s ease;
    /* Add your button styles here */
}
.btn-start-trial:hover, .btn2:hover, .btn-white:hover{
    background-color: white;
    border: .5px #F8B500 solid;
    color: #F8B500;

}

.input-field {
    position: relative;
    /* Add additional styles for input fields */
}

.input-field input {
    width: 100%;
    /* Add additional styles for input elements */
}

.input-field .label {
    position: absolute;
    /* Add additional styles for label elements */
}

.input-field .border {
    position: absolute;
    /* Add additional styles for border elements */
}
