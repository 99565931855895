.page34 {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}

@media only screen and (max-width: 510px) { 
    .page34 {
        max-width: 100%;
    }
}

