.containerr {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.page-top-features {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.page-top-box img {
  max-width: 100%;
  margin: 0 0 30px;
}

.col-lg-3, .col-lg-7 {
  padding: 15px;
}

.no-padding {
  margin-top: 80px;
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center content horizontally */
  align-items: center;
  padding-right: 1rem;
  max-width: 100%;
  margin: 0 0 30px;
}
.pic1{
  width: 800px;
  border: #393E46 3px solid;
}

.top-text {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}

h1.subpage-title, h4.subpage-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  color: #F8B500;
  text-align: center;
  margin-bottom: 20px;
}

h4 {
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  color: #393E46;
}
/* .roww{
  display: flex;
} */
.main2{
  margin-top: 90px;
}
.accordion-containerr {
  padding: 20px;
}

.accordion-item {
  border: 1px solid #F8B500;
  /* margin-bottom: 10px; */
  margin: 15px;
}

.accordion-header {
  padding: 10px;
  cursor: pointer;
  background-color: white;
  display: flex; /* Use flexbox for better alignment */
  align-items: center;
}

.accordion-title {
  margin: 0;
  font-weight: 500;
  color: #393E46;
  margin-right: 10px; 
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  color: #393E46;
  transition: max-height 0.3s ease;
}

.accordion-content.open {
  max-height: 500px;
  border-top: #393E46 .5px solid;
}

.accordion-list {
  list-style-type: none;
  padding: 10px;
  margin: 0;
}

/* RESPONSIVE STYLES */

@media (max-width: 768px) { /* For screens smaller than 768px (tablet) */
  .col-lg-3, .col-lg-7 {
    flex: 1 0 100%; /* Make columns take full width */
    padding: 10px; /* Reduce padding */
  }

  .top-text {
    padding: 10px; /* Reduce padding */
  }

  h4 {
    font-size: 20px; /* Reduce font size */
    line-height: 28px; /* Reduce line height */
  }

  .accordion-content.open {
    max-height: 300px; /* Reduce max height */
  }
}

@media (max-width: 576px) { /* For screens smaller than 576px (mobile) */
  .page-top-features {
    flex-direction: column; /* Stack elements vertically */
  }

  .col-lg-3, .col-lg-7 {
    flex: 1 0 100%; /* Make columns take full width */
    padding: 10px; /* Reduce padding */
  }

  h4 {
    font-size: 18px; /* Reduce font size */
    line-height: 26px; /* Reduce line height */
  }

  .accordion-content.open {
    max-height: 250px; /* Reduce max height */
  }
}
