  /* src/components/ContactModal/ContactModal.css */

  .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000;
    }
    .contact-text{
      text-align: center;
    }
    
    .modal-contentz {
      background: white;
      padding: 20px;
      border-radius: 5px;
      width: 300px;
      max-width: 80%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      position: relative;
    }
    
    .modal-close {
      position: absolute;
      top: 10px;
      color: #F8B500;
      right: 10px;
      background: none;
      border: none;
      font-size: 18px;
      cursor: pointer;
    }
    
    .form-group {
      margin-bottom: 15px;
    }
    
    .form-group label {
      display: block;
      margin-bottom: 5px;
    }
    
    .form-group input,
    .form-group textarea {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
    }
    
    #submit {
      background-color:#F8B500;
      color: white;
      border: none;
      cursor: pointer;
      padding: 10px 17px;
      margin: 20px auto;
      position: relative;
      left: 35%;
      border-radius: 20px;
    }
    
    button:hover {
      background-color: white;
      border: #F8B500 solid 1px;
    }
    