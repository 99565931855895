/* industries.css */

.ContainerJ {
  margin-top: 90px;
  margin-bottom: 55px;
}
.header-text {
  text-align: center;
}

.rowzr {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* margin-right: -15px; */
  margin-bottom: 20px;
  /* margin-left: 15px; */
}

a {
  color: #393e46;
}

h1.subpage-title,
h4.subpage-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  color: #f9b500;
  margin-bottom: 20px;
}

h4 {
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .colmd-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.industries-list-container {
  display: flex;
  /* justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 90px;
    padding-bottom: 50px; */
  /* padding: 20px; */
  border-bottom: #393e46 0.5px solid;
}

.industries-list {
  width: 100%;
}

@media (max-width: 1020px) {
  .industries-list-container {
    /* width: 80%; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .colmd-4 {
    /* margin-left: 190px; */
    max-width: 90%;
  }
  .rowz {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-left : 5px;
    margin-bottom: 20px;
  }
  .pic00 {
    /* border: #393e46 1px solid; */
    /* width: 100%; */
    height: auto;
    max-width: 340px;
  }
}

@media (min-width: 1021px) and (max-width: 1270px){
.rowz {
  /* justify-content: center; */
  display: flex;
  flex-wrap: wrap;
  margin-left: 70px;
  margin-bottom: 20px;
}
.pic00 {
  height: auto;
  max-width: 380px;
}
}

@media (min-width: 1271px){
  .rowz {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-right: 250px;
    margin-bottom: 20px;
  }
  .pic00 {
    height: auto;
    max-width: 380px;
  }
  }

.arrow {
  color: #f9b500;
}
.modal-head {
  background-color: none;
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    padding: 30px 30px;
    border-radius: 4px;
    max-width: 500px;
    height: 100vh;
    width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content img {
  max-width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 5px;
}
.h21 {
  /* text-align: center; */
  max-width: 90%;
  font-size: 2.4375rem;
    line-height: 50px;
    font-weight: 500;
    color: #393E46;
    margin-top: 50px;
}
.modal-content p {
  /* text-align: center; */
  /* margin: auto; */
  max-width: 90%;
  margin-bottom: 20px;

}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  margin-top: 30px;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

.close-button:hover {
  color: red;
}
