/* Footer.css */
.footer {
    background-color: #121212;
    color: #ffffff;
    padding: 2rem 0;
    font-size: 0.875rem;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1 1 200px;
    margin: 1rem;
  }
  
  .footer-section h4 {
    color: #F8B500;
    margin-bottom: 0.5rem;
  }
  
  .footer-section p {
    margin: 0.5rem 0;
  }
  
  .footer-section span {
    color: #ffffff;
  }
  
  .links a {
    display: block;
    color: #F8B500;
    margin: 0.5rem 0;
    text-decoration: none;
  }
  .social-media{
    margin-top: 40px;
  }
  #first-link{
    margin-top: 33.2px;
  }
  #facebook{
   margin-top: 33.2px;
  }
  .links a:hover {
    text-decoration: underline;
  }
  
  .social-media .social-icon {
    display: block;
    color: #F8B500;
    margin: 0.5rem 0;
    text-decoration: none;
  }
  
  .footer-bottom {
    text-align: center;
  }
  
  .footer-bottom p {
    margin: 0;
    color: #999999;
  }
  