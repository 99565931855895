/* Base styles (for desktop and larger) */

/* Container styles */
.Container2e {
  width: 97%;
  /* padding: 0 15px; */
  margin: 0 auto;
  margin-bottom: 50px;
}
.Container2e3 {
  width: 97%;
  /* padding: 0 15px; */
  margin: 0 auto;
  margin-bottom: 50px;
}
.Container2e:first-of-type {
  border-bottom: #393e46 1px solid;
}

.no-border {
  padding: 1.5rem;
  vertical-align: top;
  border-top: 0.5px solid #dee2e6;
}

.features-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.feature-item {
  flex: 1;
  margin: 0 10px;
  padding: 10px;
  padding-left: 60px;
  border-left: 0.5px solid #dee2e6;
}

.row1 {
  display: flex;
}

.plus {
  padding-left: 30px;
}

.colmd-6 {
  width: 90%;
  padding-right: 15px;
  padding-left: 15px;
  max-width: 50%;
}

.padding3 {
  padding: 15px;
}

.full-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-left: 30px;
}
.colmd-6 img:hover{
  box-shadow: 0 0 30px #f8b500,
  0 0 50px #dee2e6,
  0 0 10px #f8b500;
  }

.bright {
  border-right: 1px solid #dee2e6;
}

.reviews-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #f8b500;
}

.reviewer-name {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #f8b500;
}

.review-star {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.star-img {
  width: 20px;
  height: 20px;
  background-image: url("../../Assets/pngegg.png"); /* Replace with actual star image path */
  background-size: cover;
  margin-right: 5px;
}

.star-value {
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 10px;
}

.quote {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 1rem;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 1rem;
  color: white;
  border: 0.5px #f8b500 solid;
  text-decoration: none;
  background-color: #393e46;
  margin-top: 200px;
  border-radius: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-hvr-dark:hover {
  border: 0.5px #f8b500 solid;
}
ul.plus {
  list-style: none;
  padding-left: 0;
}
.text2 {
  color: #f8b500;
  font-size: 1.5rem;
}

ul.plus li {
  padding-left: 30px;
  line-height: 25px;
}

ul.plus li::before {
  content: "+";
  color: #f8b500;
  transform: translateY(-50%);
  font-size: 1.2rem;
  padding-right: 10px;
}
/* Media Queries */

/* Mobile: below 769px */
@media (max-width: 768px) {
  .features-container {
    flex-direction: column;
  }
  .row1 {
    display: flex;
    flex-direction: column;
  }
  .bright {
    border-right: none;
  }
  .feature-item {
    margin: 10px 0;
    border-left: none;
    border-bottom: 0.5px solid #f8b500;
  }
  .features-container:last-of-type .feature-item:last-of-type {
    border: none;
  }

  .colmd-6 {
    max-width: 100%;
  }

  .full-image {
    margin-left: 0;
    border: 1px solid #f8b500;
  }

  .btn {
    margin-top: 100px;
  }

  .reviews-title {
    font-size: 1.5rem;
  }

  .review-star {
    flex-direction: row;
    align-items: flex-start;
  }

  .star-img {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .star-value {
    margin-left: 10px;
  }
}

/* Tablet: 770px to 1024px */
@media (min-width: 769px) and (max-width: 1294px) {
  .features-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .features-container:first-of-type {
    /* border-bottom: black .5px solid; */
  }
  .feature-item {
    margin: 10px;
    border-left: 0.5px solid #dee2e6;
  }

  .colmd-6 {
    max-width: 100%;
    width: 100%;
  }

  .full-image {
    margin-left: 0;
  }

  .btn {
    margin-top: 150px;
  }

  .reviews-title {
    font-size: 1.75rem;
  }
}
