.header {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  padding: 8px 16px;
  background-color: white;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border: 0.5px #393E46 solid;
  border-top: none;
  border-left: none;
  border-right: none;
}

.header-scrolled {
  background: white;
  box-shadow: 0 4px 6px rgba(248, 181, 0, 0.2);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-size: 1.25rem;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
  width: 200px; /* Adjust as needed */
  height: auto;
}

.logo-scrolled {
  color: #2d3748; /* text-gray-800 */
}

.nav {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  padding-left: 250px;
  width: 100%;
  background-color: white;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-open {
  display: flex;
}
/* Dropdown for "Solutions" */
.dropdown-menu {
  position: absolute;
  top: 100%; /* Align below the parent element */
  left: -1010%;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px 15px;
  z-index: 10;
  width: 1200px;
}

.dropdown-item {
  font-size: 0.9rem;
  display:inline-block;
  width: 150px;
  padding: 8px 12px;
  color: #393e46;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  cursor: pointer;
  /* border-bottom: 1px solid #f0f0f0; */
  padding-bottom: none;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #f8b500;
  color: white;
}

.solutions-link {
  position: relative;
}

.nav-link {
  font-size: 1.125rem;
  text-decoration: none;
  margin: 8px 0;
  color: #393E46;
  transition: color 0.3s ease-in-out;
}

.nav-link-scrolled {
  color: #393E46; 
}

.nav-link-scrolled:hover {
  color: #F8B500; 
}

.nav-link:hover {
  color: #F8B500; 
}
.inner-box-border{
  display: inline-flex;
}
.column{
  padding:3px 1px;
  border-right: #F8B500 2px solid;
  color: #393E46;
}
li{
  list-style: none;
  padding: 7px;
  padding-right: 18px;
}
li:hover{
color: #F8B500;
}

@media (min-width: 1024px) {
  .nav {
    display: flex;
    flex-direction: row;
    position: static;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    padding-left: 250px;
  }

  .nav-link {
    margin: 0 28px;
  }
}

.menu-icon {
  display: block;
  cursor: pointer;
  margin-right: 40px;
  font-size: 1.5rem;
}

@media (min-width: 1024px) {
  .menu-icon {
    display: none;
  }
}
@media (max-width: 768px) {
  .nav{
    overflow-y: auto; /* Enable vertical scrolling */ 
    max-height: calc(100vh - 80px); /* Set the maximum height to the viewport height minus the header height */
  }
  .dropdown-menu {
    position: static;
    box-shadow: none;
    border: none;
    padding: 0;
    width: 100%;
    display: none;
  }

  .solutions-link:hover .dropdown-menu {
    display: flex;
    flex-direction: column;
  }

  .inner-box-border {
    display: flex;
    flex-direction: column;
  }

  .column {
    border-right: none;
    border-bottom: #F8B500 2px solid;
  }

  .dropdown-item {
    width: 100%;
  }
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 10px 20px;
  top: 0;
  color: white;
}

.contact-info {
  display: flex;
  gap: 10px;
}

.divider {
  margin: 0 10px;
}

.actions {
  display: flex;
  gap: 20px;
}

button {
  background-color: black;
  color: white;
  border: 1px solid white;
  padding: 10px 20px;
  cursor: pointer;
  text-transform: uppercase;
}

button:hover {
  background-color: white;
  color: black;
}
